
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component
  export default class PagePropertyPartGeneralHeader extends Vue {
    @Prop({ required: true }) address!: Address;
    @Prop({ required: true }) activityId!: number;
    @Prop({ required: false }) lastUpdated!: string;
    @Prop({ required: false }) tab!: string;
  }
